import Vue from 'vue'
import ElementUI from 'element-ui';
import Avue from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'
import router from './router.js';
import App from './App.vue'
import './styles/common.scss'
import '@/utils/es6'
import {computedUrl} from './utils/utils'
import '@/mock/'
//导入主题文件
import '@/theme/index.js'
import apiConfig from "./api/config";

Vue.config.productionTip = false
window.axios = axios;
Vue.use(Avue, {
    size: 'mini'
});
Vue.prototype.$website = {
    ...window.$website,
    url: apiConfig.devURI + window.$website.url
};

Vue.prototype.$websiteUrl = apiConfig.devURI + window.$website.url;
Vue.prototype.computedImgUrl = (imgUri) => {
    return computedUrl(apiConfig.devURI, imgUri, router.options.base)
};
Vue.use(ElementUI);
Vue.use(dataV)
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
