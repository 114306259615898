import {validatenull} from '@/echart/util'

export const uuid = () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}
export const createFile = () => {
    return {
        "title": "文件夹",
        "name": "文件夹",
        "index": uuid(),
        "children": []
    }
}
export const compare = (propertyName) => {
    return function (object1, object2) {
        var value1 = object1[propertyName];
        var value2 = object2[propertyName];
        if (value2 < value1) {
            return -1;
        } else if (value2 > value1) {
            return 1;
        } else {
            return 0;
        }
    }
}

export const funEval = (value) => {
    return new Function("return " + value + ";")();
}


export const getFunction = (fun, def) => {
    if (!validatenull(fun)) {
        try {
            return funEval(fun)
        } catch {
            return () => {
            }
        }
    } else if (def) return () => {
    }
}
export const getJson = (str) => {
    if (validatenull(str)) return {};
    else if (typeof str == "string") {
        try {
            return JSON.parse(str);
        } catch {
            return {}
        }
    } else {
        return str;
    }
}
export const checkUrl = (url) => {
    var reg = /http(s)?:\/\/([\w-.]+)+(:[0-9]+)?.*$/;
    if (!reg.test(url)) {
        return false;
    } else {
        return true
    }
}
export const getUrlParam = name => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    } else {
        return null;
    }
}
export const isLocalUrl = (url) => {
    let local = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
    let reg = new RegExp(`^(${local})\\S*`);
    return reg.test(url);
}
export const computedUrl = (baseUri, imgUri, staticUri) => {
    if (/^(http|https):\/\/([\w.]+\/?)\S*/.test(imgUri)) {
        return imgUri;
    }
    if (/^(data)\S*/.test(imgUri)) {
        return imgUri;
    }
    if (/^\/(DataV)\S*/.test(imgUri)) {
        return imgUri;
    }
    if (/^\/(img)\S*/.test(imgUri)) {
        return staticUri + imgUri;
    }
    if (/^\/(api)\S*/.test(imgUri)) {
        return baseUri + imgUri;
    }
    if (/^\/(dev)\S*/.test(imgUri)) {
        return imgUri;
    }
    return imgUri;
}
